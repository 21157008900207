import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import "../styles/Register.css";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { registerPindahan } from "../api_folder/api_register";
import { getMovingActiveBatch } from "../api_folder/api";
function RegisterPindahan() {
  const [errMessage, setErrMessage] = useState('');
  // eslint-disable-next-line
  const [batch, setBatch] = useState([]);
  // eslint-disable-next-line
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [school, setSchool] = useState('');
  const [gender, setGender] = useState();
  const [birthday, setBirtday] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [source, setSource] = useState();
  const [phonewa, setPhonewa] = useState('');
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);

  const navigate = useNavigate();
  const loc = useLocation();

  const regex = /(<([^>]+)>)/gi;

  const onFormSubmit = e => {
    let onSendMov = []
    e.preventDefault();
    registerPindahan(loc.state.id_batch, name, gender, birthday, birthplace, source, phonewa, email, school)
      .then((res) => {
        if (res.data.status === 'fail') setErrMessage(res.data.message);
        if (res.data.status === 'success') {
          onSendMov = res.data;
          navigate('/register-success', { state: onSendMov })
        }
      })
  }

  useEffect(() => {
    getMovingActiveBatch().then((res) => {
      setBatch(res.data);
    })

  }, [])

  useEffect(() => {
    setErrMessage('');
  }, [id, name, gender, birthday, birthplace, source, phonewa, email, school])

  return (
    <section>
      <Navbar expand="sm" variant="custom" sticky="top">
        <Navbar.Brand>
          <img
            src="/images/logo.svg"
            width="44"
            height="45"
            className="d-inline-block align-top ms-3"
            alt="logo"
          />
        </Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Link className="me-3">
            PPDB Asy-Syukriyyah Islamic School
          </Nav.Link>
        </Nav>
      </Navbar>
      <div className="position-relative ">
        <div className="forText">
          <Link to={-1} className="back">
            <BsIcons.BsArrowLeftShort />
            Kembali ke halaman sebelumnya
          </Link>
        </div>
      </div>
      <Container className="v-screen" style={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <h3 style={{ marginTop: '10rem' }}>Form Registrasi Pindahan</h3>
        <p style={{ color: "#00923f" }}>
          Formulir untuk pendaftaran siswa/i pindahan. Untuk ketersediaan kuota
          pindahan, mohon hubungi panitia PPDB Unit yang dituju terlebih dahulu.
        </p>

        <Form onSubmit={onFormSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100">
                <Form.Label>
                  <b>Unit Tujuan</b>
                </Form.Label>
                {/* {batch.status !== 'fail' && (
                  <Form.Select onChange={e => { setId(e.target.value) }} value={id}>
                    <option>Pilih program/unit pendidikan</option>
                    {batch?.map((g, i) => (
                      <option key={i} value={g.id_batch}>{g.name_unit}</option>
                    ))}
                  </Form.Select>
                )} */}
                <Form.Select disabled>
                  <option value={loc.state.id_batch}>{loc.state.name_unit}</option>
                </Form.Select>
                <Form.Text className="text-muted">Pastikan anda memilih unit pendidikan yang sesuai</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formicEmail">
                <Form.Label>
                  <b>Nama</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan nama peserta didik"
                  onChange={e => { setName(e.target.value) }}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBacEmail">
                <Form.Label>
                  <b>Asal sekolah</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan nama sekolah sebelumnya"
                  onChange={e => { setSchool(e.target.value) }}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBasic">
                <Form.Label>
                  <b>Jenis kelamin</b>
                </Form.Label>
                <Form.Select onChange={e => { setGender(e.target.value) }} value={gender}>
                  <option>Pilih jenis kelamin peserta didik</option>
                  <option value={1}>Laki-laki</option>
                  <option value={0}>Perempuan</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBacEmail">
                <Form.Label>
                  <b>Tempat lahir</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan tempat lahir peserta didik"
                  onChange={e => { setBirthplace(e.target.value) }}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formaicmail">
                <Form.Label>
                  <b>Tanggal lahir</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Masukkan tanggal lahir peserta didik"
                  onChange={e => { setBirtday(e.target.value) }}
                />
                <Form.Text className="text-muted">klik untuk memilih tanggal lahir</Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100" controlId="formBal">
                <Form.Label>
                  <b>Nomor telepon/Whatsapp Orang tua</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Masukkan nomor telepon orang tua"
                  onChange={e => { setPhonewa(e.target.value) }}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                <Form.Label>
                  <b>Email</b>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Masukkan email"
                  onChange={e => { setEmail(e.target.value) }}
                />
              </Form.Group>
              <hr
                style={{
                  background: "none",
                  color: "black",
                  borderColor: "black",
                  height: "3px",
                  width: "100%",
                }}
              />
              <Form.Group className="mb-3 w-100" controlId="formBasic">
                <Form.Label>
                  <b>
                    Dari mana Anda mengetahui Asy-Syukriyyah Islamic School?
                  </b>
                </Form.Label>
                <Form.Select
                  onChange={e => { setSource(e.target.value) }}
                  value={source}
                >
                  <option>Pilih sumber</option>
                  <option value={"Website"}>Website</option>
                  <option value={"Facebook"}>Facebook</option>
                  <option value={"Instagram"}>Instagram</option>
                  <option value={"Whatsapp"}>Whatsapp/Line/Telegram</option>
                  <option value={"Brosur"}>Brosur</option>
                  <option value={"Alumni"}>Alumni</option>
                  <option value={"Teman"}>Teman</option>
                  <option value={"Saudara"}>Saudara</option>
                </Form.Select>
              </Form.Group>

              <hr
                style={{
                  background: "none",
                  color: "black",
                  borderColor: "black",
                  height: "3px",
                  width: "100%",
                }}
              />
              {errMessage && (
                <Alert variant="danger">
                  {errMessage.replace(regex, ' ')}
                </Alert>
              )}
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                  onChange={(e) => setCheck(e.target.checked)}
                />
              </Form.Group>
              <Button variant="daftar" type="submit" className="rounded-pill" disabled={!check}>
                Daftar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <span className="circlex" />
      <span className="circley" />
    </section>
  );
}
export default RegisterPindahan;
