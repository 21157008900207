import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table, Modal, Form, Alert } from "react-bootstrap";
// import { dataRegister } from "../data";
import Profile from "./Profile";
import DataPeriodik from "./DataPeriodik";
import * as BsIcons from "react-icons/bs";
import * as BsAi from "react-icons/ai";
import { getDataPersonal, getDataForm, cekProsesPendaftaran, cekStatusLulus } from "../api_folder/api";
import { Link } from "react-router-dom";
import DataWali from "./DataWali";
import { sendDataForm } from "../api_folder/api_sendData";


const DashboardProfile = () => {
  const localUsrnm = localStorage.getItem('username');

  const [download, setDownload] = useState('')
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (localUsrnm, namaLengkap, schoolBefore, gender, birthplace, phone, email) => {
    setShow(localUsrnm)
    setNamaLengkap(namaLengkap);
    setSchoolBefore(schoolBefore)
    setGender(gender);
    setBirthplace(birthplace)
    setPhone(phone)
    setEmail(email)
    setMessage();
  }

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (sid, sname, susrname, spass) => {
    setShow1(susrname)
    setSID(sid)
    setSName(sname)
    setSUsrname(susrname)
    setPass(spass)
  }

  const [dataReg, setDataReg] = useState([]);
  const [personal, setPersonal] = useState([]);

  const [cek, setCek] = useState([]);
  const [sId, setSID] = useState('');
  const [sUsrname, setSUsrname] = useState('');
  const [pass, setPass] = useState('');
  const [sname, setSName] = useState('');
  const [vabaru, setVabaru] = useState('');

  const [namaLengkap, setNamaLengkap] = useState('');
  const [gender, setGender] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [schoolBefore, setSchoolBefore] = useState('');
  const [othersSchool, setOthersSchool] = useState('');

  const [message, setMessage] = useState('');
  // eslint-disable-next-line
  const [tampil, setTampil] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    sendDataForm(localUsrnm, namaLengkap, gender, birthplace, phone, email, schoolBefore, othersSchool).then((res) => {
      if (res.data.message === 'input form berhasil') {
        handleClose();
        refreshData(localUsrnm);
      } else {
        setMessage(res.data.message)
      }
    })
  }
  const refreshData = (dataUsrnm) => {
    getDataForm(dataUsrnm).then((res) => {
      setDataReg(res.data);
      setNamaLengkap(res.data.name_student);
      setGender(res.data.gender);
      setBirthplace(res.data.birthplace)
      setPhone(res.data.phone_number)
      setEmail(res.data.email)
      setSchoolBefore(res.data.school_before)
      setOthersSchool(res.data.others_school)
    })
  }

  useEffect(() => {
    refreshData(localUsrnm);

    getDataPersonal(localUsrnm).then((res) => {
      setPersonal(res.data);
    })

    cekStatusLulus(localUsrnm).then((res) => {
      setSID(res.data.id_status)
      setSUsrname(res.data.username)
      setPass(res.data.status_pass)
      setSName(res.data.name_student)
      setDownload(res.data.link_download)
      setVabaru(res.data.va_baru)
    })

  }, [localUsrnm])

  useEffect(() => {
    cekProsesPendaftaran(localUsrnm).then((res) => {
      setCek(res.data);
    })

  }, [localUsrnm])

  useEffect(() => {
    setMessage('');
  }, [namaLengkap, gender, birthplace, phone, email, schoolBefore, othersSchool])

  return (
    <>
      <div className="p-5 overflow-hidden">
        <Container fluid className="position-relative">
          <div className="gradient-top-right"></div>
          <div className="gradient-right"></div>
          <div className="d-flex point gap-3 mb-2">
            <h2 className="fw-medium">Profile</h2>
          </div>
          <Row style={{ gap: "3px" }} className="mt-5">
            <Col lg={4}>
              <h5>Data Registrasi</h5>
              <div className="profile-register">
                <Table className="table-register">
                  <tbody>
                    <tr>
                      <td>No. Pendaftaran</td>
                      <td>{dataReg.vaccount}</td>
                    </tr>
                    <tr>
                      <td>Unit</td>
                      <td>{dataReg.name_unit}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>{dataReg.name_student}</td>
                    </tr>
                    <tr>
                      <td>Asal Sekolah</td>
                      <td>
                        {dataReg.school_before === '1' ? 'TKIT Asy-Syukriyyah'
                          : dataReg.school_before === '2' ? 'SDIT Asy-Syukriyyah'
                            : dataReg.school_before === '3' ? 'MI Plus Asy-Syukriyyah'
                              : dataReg.school_before === '4' ? 'SMPIT Asy-Syukriyyah'
                                : dataReg.school_before === '5' ? 'MTS Plus Asy-Syukriyyah'
                                  : dataReg.school_before === '6' ? dataReg.others_school
                                    : dataReg.school_before === '7' ? 'SDIT Quran Asy-Syukriyyah Faturrahman'
                                      : dataReg.school_before === '8' ? 'SMPIT Quran Asy-Syukriyyah'
                                        : '???'
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Jenis Kelamin</td>
                      <td>{dataReg.gender === '0' ? ("Perempuan") : ("Laki-Laki")}</td>
                    </tr>
                    <tr>
                      <td>Tempat Lahir</td>
                      <td>{dataReg.birthplace}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Lahir</td>
                      <td>{dataReg.birthday}</td>
                    </tr>
                    <tr>
                      <td>No. WhatsApp</td>
                      <td>{dataReg.phone_number}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{dataReg.email}</td>
                    </tr>
                  </tbody>
                </Table>
                <Button variant="light" className="btn-edit-profile" onClick={() => handleShow(localUsrnm, namaLengkap, schoolBefore, gender, birthplace, phone, email)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-edit"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                    <path d="M16 5l3 3"></path>
                  </svg>
                  Edit
                </Button>
              </div>
              <div className="mt-3 status mx-auto">
                <h4>Status Kelulusan</h4>
                <div className="d-flex gap-2">
                  <Button className="status-text w-100" onClick={() => handleShow1(sId, sname, sUsrname, pass)} >Cek Status</Button>
                </div>
              </div>
            </Col>
            <Col xs={12} md={12} lg={7} className="mt-sm-3">
              <ul className="nav nav-fils flex-column gap-3" id="parentM">
                <li className="nav-item bg-gradient">
                  <a
                    href="#dataPribadi"
                    className={`nav-link text-dark ${cek.data_pribadi === 0
                      ? "dropdown-form"
                      : "bg-berhasil text-white"
                      }  d-flex align-items-center justify-content-between rounded`}
                    data-bs-toggle="collapse"
                    aria-current="page"
                  >
                    Data Pribadi
                    {cek.data_pribadi === 0 ? (
                      <BsIcons.BsChevronDown />
                    ) : (
                      <BsAi.AiOutlineCheck />

                    )}
                  </a>
                  <ul
                    className="nav flex-column collapse"
                    id="dataPribadi"
                    data-bs-parent="parentM"
                  >
                    <li className="nav-item">
                      <Profile />
                    </li>
                  </ul>
                </li>
                <li className="nav-item bg-gradient">
                  <a
                    href="#dataPriodik"
                    className={`nav-link text-dark ${cek.data_periodik === 0
                      ? "dropdown-form"
                      : "bg-berhasil text-white"
                      }  d-flex align-items-center justify-content-between rounded`}
                    data-bs-toggle="collapse"
                    aria-current="page"
                  >
                    Data Periodik
                    {cek.data_periodik === 0 ? (
                      <BsIcons.BsChevronDown />
                    ) : (
                      <BsAi.AiOutlineCheck />
                    )}
                  </a>
                  <ul
                    id="dataPriodik"
                    className="nav flex-column collapse"
                    data-bs-parent="parentM"
                  >
                    <li className="nav-item">
                      <DataPeriodik />
                    </li>
                  </ul>
                </li>
                <li className="nav-item bg-gradient">
                  <a
                    href="#kartuKeluarga"
                    className={`nav-link text-dark ${cek.data_kk === 0
                      ? "dropdown-form"
                      : "bg-berhasil text-white"
                      }  d-flex align-items-center justify-content-between rounded`}
                    data-bs-toggle="collapse"
                    aria-current="page"
                  >
                    Kartu Keluarga
                    {cek.data_kk === 0 ? (
                      <BsIcons.BsChevronDown />
                    ) : (
                      <BsAi.AiOutlineCheck />
                    )}
                  </a>
                  <ul
                    id="kartuKeluarga"
                    className="nav flex-column collapse"
                    data-bs-parent="parentM"
                  >
                    <li className="nav-item">
                      <section className="data-profile">
                        <div className="position-relative">
                          <Link
                            to={"/dashboard/edit-kk"}
                            variant="light"
                            className="btn-edit-dataDiri position-absolute top-0 end-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-edit fw-bold"
                              width="100"
                              height="100"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                              <path d="M16 5l3 3"></path>
                            </svg>
                            <span className="fw-500">Edit</span>
                          </Link>
                        </div>
                        <div className="mt-5">
                          <div className="d-flex flex-column justify-content-center">
                            <div className="file-kk mx-auto">
                              {personal.file_kk ? (
                                <img className="w-100" style={{ objectFit: 'cover' }} src={personal.file_kk} alt="img" />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="57"
                                  height="57"
                                  fill="currentColor"
                                  className="bi bi-file-earmark-fill text-light border-5 border-dark"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z" />
                                </svg>
                              )}

                            </div>
                            {!personal.file_kk ? (
                              <button
                                className="status-text p-1 mx-auto mt-3"
                                style={{ width: "120px" }}
                              >
                                Belum Terisi

                              </button>
                            ) : (<div className="mt-5"></div>)}
                            <span style={{ fontSize: '12px', alignContent: 'center', textAlign: 'center', marginTop: '1rem' }}>Kartu keluarga yang di upload wajib yang ber-QR Code atau keluaran terbaru</span>

                          </div>
                        </div>
                      </section>
                    </li>
                  </ul>
                </li>
                <li className="nav-item bg-gradient">
                  <a
                    href="#dataWali"
                    className={`nav-link text-dark ${cek.data_ortu === 0
                      ? "dropdown-form"
                      : "bg-berhasil text-white"
                      }  d-flex align-items-center justify-content-between rounded`}
                    data-bs-toggle="collapse"
                    aria-current="page"
                  >
                    Data Orang Tua/Wali
                    {cek.data_ortu === 0 ? (
                      <BsIcons.BsChevronDown />
                    ) : (
                      <BsAi.AiOutlineCheck />
                    )}
                  </a>
                  <ul
                    id="dataWali"
                    className="nav flex-column collapse"
                    data-bs-parent="parentM"
                  >
                    <li className="nav-item">
                      <DataWali />
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Data Registrasi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message && (
              <Alert variant="info">
                {message}
              </Alert>
            )}
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Nama Lengkap</Form.Label>
              <Form.Control type="text" placeholder="Masukkan Nama Lengkap peserta didik" value={namaLengkap} onChange={e => setNamaLengkap(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="schoolBefore">
              <Form.Label >Asal Sekolah</Form.Label>
              <Form.Select disabled type="text" placeholder="Masukkan nama sekolah sebelumnya" value={schoolBefore} onChange={e => { setSchoolBefore(e.target.value) }}>
                <option value="">Pilih Asal Sekolah</option>
                <option value={1}>TKIT Asy-Syukriyyah</option>
                <option value={2}>SDIT Asy-Syukriyyah</option>
                <option value={3}>MI Plus Asy-Syukriyyah</option>
                <option value={4}>SMPIT Asy-Syukriyyah</option>
                <option value={5}>MTS Asy-Syukriyyah</option>
                <option value={6}>Lainnya</option>
                <option value={7}>SDIT Quran Asy-Syukriyyah Faturrahman</option>
                <option value={8}>SMPIT Quran Asy-Syukriyyah</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="schoolBefore">
              <Form.Label>Asal Sekolah</Form.Label>
              <Form.Control readOnly type="text" placeholder="Masukkan nama sekolah sebelumnya" value={othersSchool} onChange={e => setOthersSchool(e.target.value)} />
            </Form.Group>
            <Form.Label>Jenis Kelamin</Form.Label>
            <Form.Select className="mb-3" aria-label="Default select example" value={gender} onChange={e => setGender(e.target.value)}>
              <option>Pilih jenis kelamin</option>
              <option value={1}>Laki-Laki</option>
              <option value={0}>Perempuan</option>
            </Form.Select>
            <Form.Group className="mb-3" controlId="birthplace">
              <Form.Label>Tempat Lahir</Form.Label>
              <Form.Control type="text" placeholder="Masukkan tempat lahir peserta didik" value={birthplace} onChange={e => setBirthplace(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phonenumber">
              <Form.Label>Nomor telepon/Whatsapp orang tua</Form.Label>
              <Form.Control type="number" placeholder="Masukkan nomor telepon orang tua" value={phone} onChange={e => setPhone(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Masukkan email" value={email} onChange={e => setEmail(e.target.value)} />
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" type="submit">
              Edit data
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Nomor Pendaftaran {sUsrname}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 tengah">
            {vabaru !== "" ? <BsIcons.BsFillCheckCircleFill color="green" size={100} className="mb-3"/>:
            <BsIcons.BsFillExclamationTriangleFill color="orange" size={100} className="mb-3"/>}
            <h6>{pass}</h6>
            {vabaru !== "" ? <h5>{vabaru}</h5> : <></>}
          </div>
        </Modal.Body>
        {vabaru !== "" ?
          <Modal.Footer>
            <Button variant="success" href={download}>
              Download Status
            </Button>
          </Modal.Footer>
          : <></>}
      </Modal>
    </>

  );
};

export default DashboardProfile;
