import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import "../styles/Login.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, NavLink, useNavigate } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import { Carousel, Col, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { fotoHome } from "../data";
import { login } from "../api_folder/api";
import Alert from "react-bootstrap/Alert";
import AuthContext from "../api_folder/AuthProvider";

function Login() {
  const regex = /(<([^>]+)>)/gi;
  const { setAuth } = useContext(AuthContext);
  let userAuth = [];
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState([]);
  const [usernameInput, setUsernameInput] = useState([]),
    onInput = ({ target: { value } }) => setUsernameInput(value),
    handleSubmit = (e) => {
      e.preventDefault();
      login(usernameInput, passwordInput).then((res) => {
        if (res.data.status === 200) {
          userAuth = res.data;
          // localStorage.setItem("token", res.data.token);
          localStorage.setItem("username", res.data.username);
          setAuth(userAuth.username, userAuth.token, userAuth.expireAt)
          navigate('/dashboard', {
            state: userAuth
          })
        } else if (res.data.status === 'fail') {
          setErrMsg(res.data.message.replace(regex, " "))
        }
      })
    }

  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  }
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  useEffect(() => {
    setErrMsg('');
  }, [usernameInput, passwordInput])

  return (
    <section >
      <Navbar variant="customLogin">
        <Container>
          <NavLink
            to="/"
            className="text-white d-flex gap-2 align-items-center">
            <img
              alt="logo yayasan"
              src="/images/logo.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            <p>PPDB Asy-Syukriyyah Islamic School Dashboard</p>
          </NavLink>
        </Container>
      </Navbar>
      <span className="circle1" />
      <span className="circle2" />
      <Container>
        <Row className="justify-content-between">
          <Col md={6} xl={5} className="gap-3 col-img">
            <Carousel interval={2500} className="overflow-hidden" prevIcon nextIcon indicators={false} slide={false}>
              {fotoHome?.map((e, i) => (
                <Carousel.Item key={i}>
                  <img
                    className="d-block w-100"
                    src={e.foto}
                    alt={e.alter}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col md={6} xl={5} className="form-head">
            {errMsg ? (
              <Alert variant="danger" className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</Alert>
            ) : (
              <span></span>
            )}

            <Form className="form-login " onSubmit={handleSubmit}>
              <h3 className="mb-3" style={{ fontSize: "34px" }}>
                Login
              </h3>
              <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" placeholder="Masukkan username" onChange={onInput} required />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBasicPassword">

                <Form.Label>Kata sandi</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type={passwordType}
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    placeholder="Masukan kata sandi"
                    required
                  />
                  <div onClick={togglePassword}>
                    {
                      passwordType === 'password' ? (<BsIcons.BsEyeSlash style={{ color: '#9E9E9EB2', width: '32px' }} />)
                        : (<BsIcons.BsEye style={{ color: '#00491F', width: '32px' }} />)
                    }

                  </div>
                </div>

              </Form.Group>
              <Button
                variant="login"
                type="submit"
                className="mb-3 w-100 rounded-pill"
              >
                Login
              </Button>
              {/* <Form.Text className="text-muted mb-5">
                Belum mendaftar?{" "}
                <Link
                  to="/register"
                  style={{ color: "#00923F", textDecoration: "none" }}
                >
                  Daftar Sekarang
                </Link>
              </Form.Text> */}
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#00923F" }}
                className="mt-5"
              >
                <BsIcons.BsArrowLeftShort />
                Kembali ke halaman utama
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Login;
