import { Col, Container, Row } from "react-bootstrap";
import "../styles/RegisterFlow.css";
// import Button from "react-bootstrap/Button";
// import * as AiIcons from "react-icons/ai";
// import { Link } from "react-router-dom";

function RegisterFlow() {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <div className="page3">
                            <div className="divTitle">
                                <h2 className="titleAlur">Alur Pendaftaran</h2>
                            </div>
                            <div className="divTitle">
                                <p style={{ color: '#697586' }} className="mt-1">Alur pendaftaran calon perserta didik baru reguler dan internal di Asy-Syukriyyah Islamic School</p>
                            </div>
                            <div className="content">
                                <div  className="text-center imgResp">
                                    <a href="/images/image3.png">
                                        <img src="/images/image3.png" alt="Gambar Alur Eksternal" className="img-fluid" />
                                    </a>
                                </div>
                                {/* <div className="mt-3">
                                    <Link to='/register-reguler'>
                                        <Button variant="login" className="rounded-pill tryCenterIcon">
                                            <AiIcons.AiOutlineFile /> Daftar Reguler
                                        </Button>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default RegisterFlow;