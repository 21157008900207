import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import "../styles/Register.css";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getInternalActiveBatch, getUserInternal } from "../api_folder/api";
import { registerInternal } from "../api_folder/api_register";
import { useNavigate } from "react-router";

// WORK IN PROGRESS

function RegisterInternal() {
  const regex = /(<([^>]+)>)/gi;
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState("");
  // eslint-disable-next-line
  const [batch, setBatch] = useState([]);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [school, setSchool] = useState("");
  const [gender, setGender] = useState();
  const [birthday, setBirtday] = useState("");
  const [birthplace, setBirthplace] = useState("");
  const [source, setSource] = useState();
  const [phone, setPhone] = useState("");
  const [phonewa, setPhonewa] = useState("");
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState(false);

  // const [tjn, setTjn] = useState([]);
  const [ids, setIds] = useState("");
  const sesStorage = sessionStorage.getItem("nis");

  let batchTK = [
    {
      id_batch: 92,
      name_unit: "SDIT Asy-Syukriyyah",
    },
    {
      id_batch: 93,
      name_unit: "MI Plus Asy-Syukriyyah",
    },
    {
      id_batch: 98,
      name_unit: "SDIT Quran Asy-Syukriyyah Faturrahman",
    },
  ];

  let batchSD = [
    {
      id_batch: 94,
      name_unit: "SMPIT Asy-Syukriyyah",
    },
    {
      id_batch: 95,
      name_unit: "SMPIT Quran Asy-Syukriyyah",
    },
    {
      id_batch: 96,
      name_unit: "MTS Plus Asy-Syukriyyah",
    },
  ];

  let batchSMP = [
    {
      id_batch: 97,
      name_unit: "SMAIT Asy-Syukriyyah",
    },
  ];

  let onSendRes = [];
  const onSubmit = (e) => {
    e.preventDefault();
    registerInternal(
      sesStorage,
      id,
      name,
      gender,
      birthday,
      birthplace,
      source,
      phonewa,
      email,
      school
    ).then((res) => {
      if (res.data.status === "fail") setErrMessage(res.data.message);
      if (res.data.status === "success") {
        onSendRes = res.data;
        navigate("/register-internal-success", { state: onSendRes });
      }
    });
    // console.log(id, name, gender, birthday, birthplace, source, phone, phonewa, email);
  };

  useEffect(() => {
    if (ids === "1") setSchool("Toddler TKIT Asy-Syukriyyah");
    if (ids === "2") {
      setSchool("Play Group TKIT Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit === '3'))
    }
    if (ids === "3") {
      setSchool("Kelas A TKIT Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit === '4'))
    }
    if (ids === "4") {
      setSchool("Kelas B TKIT Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit >= '5' && unit.id_unit <= '6'))
    }
    if (ids === "5") {
      setSchool("SDIT Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit >= '7' && unit.id_unit <= '8'))
    }
    if (ids === "6") {
      setSchool("MI Plus Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit >= '7' && unit.id_unit <= '8'))
    }
    if (ids === "7") {
      setSchool("SMPIT Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit === '9'))
    }
    if (ids === "8") {
      setSchool("MTS Plus Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.id_unit === '9'))
    }
    if (ids === "9") setSchool("SMAIT Asy-Syukriyyah");
    if (ids === "10") {
      setSchool("SDIT Quran Asy-Syukriyyah Faturrahman");
      // setTjn(batch.filter(unit => unit.id_unit === '8'))
    }
    if (ids === "11") {
      setSchool("SMPIT Quran Asy-Syukriyyah");
      // setTjn(batch.filter(unit => unit.grup_unit === '6'))
    }
    // eslint-disable-next-line
  }, [ids]);

  useEffect(() => {
    getInternalActiveBatch().then((res) => {
      setBatch(res.data);
    });
  }, []);

  useEffect(() => {
    getUserInternal(sesStorage).then((res) => {
      setName(res.data.name_student);
      setGender(res.data.gender);
      setBirtday(res.data.birthday);
      setBirthplace(res.data.birthplace);
      setSource();
      setPhone(0);
      setPhonewa(res.data.phone_number);
      setEmail(res.data.email);

      setIds(res.data.id_unit);
    });
  }, [sesStorage]);

  useEffect(() => {
    setErrMessage("");
  }, [
    id,
    name,
    gender,
    birthday,
    birthplace,
    source,
    phone,
    phonewa,
    email,
    school,
  ]);

  useEffect(() => {
    if (sesStorage === null) navigate("/register-internal");
  });

  return (
    <section>
      <Navbar expand="sm" variant="custom" sticky="top">
        <Navbar.Brand>
          <img
            src="/images/logo.svg"
            width="44"
            height="45"
            className="d-inline-block align-top ms-3"
            alt="logo"
          />
        </Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Link className="me-3">
            PPDB Asy-Syukriyyah Islamic School
          </Nav.Link>
        </Nav>
      </Navbar>
      <div className="position-relative ">
        <div className="forText">
          <Link to="/" className="back">
            <BsIcons.BsArrowLeftShort />
            Kembali ke halaman utama
          </Link>
        </div>
      </div>
      <Container
        className="v-screen"
        style={{ marginTop: "2rem", marginBottom: "1rem" }}
      >
        <h3 style={{ marginTop: "10rem" }}>Form Registrasi Internal</h3>
        <p style={{ color: "#00923f" }}>
          Mohon cek data di bawah ini. Silahkan lengkapi jika terdapat
          pembaruan. Klik tombol ‘Daftar’ jika data telah sesuai.
        </p>

        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100">
                <Form.Label>
                  <b>Unit Tujuan</b>
                </Form.Label>
                <Form.Select onChange={e => setId(e.target.value)} value={id}>
                  <option>Pilih program/unit pendidikan</option>
                  {ids === "5" || ids === "6" || ids === "10" ? (
                    batchSD.map((e, i) => (
                      <option key={i} value={e.id_batch}>{e.name_unit}</option>
                    ))
                  ) : ids === "7" || ids === "8" || ids === "11" ? (
                    batchSMP.map((e, i) => (
                      <option key={i} value={e.id_batch}>{e.name_unit}</option>
                    ))
                  ) : ids === "4" ? (
                    batchTK.map((e, i) => (
                      <option key={i} value={e.id_batch}>{e.name_unit}</option>
                    ))
                  ) : (
                    <option value="null">Batch tidak tersedia</option>
                  )}
                </Form.Select>
                <Form.Text className="text-muted">
                  Pastikan anda memilih unit pendidikan yang sesuai
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="name">
                <Form.Label>
                  <b>Nama</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan nama peserta didik"
                  defaultValue={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="schoolfrom">
                <Form.Label>
                  <b>Asal sekolah</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan nama sekolah sebelumnya"
                  value={school}
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="gender">
                <Form.Label>
                  <b>Jenis kelamin</b>
                </Form.Label>
                <Form.Select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option>Pilih jenis kelamin peserta didik</option>
                  <option value={1}>Laki-laki</option>
                  <option value={0}>Perempuan</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBacEmail">
                <Form.Label>
                  <b>Tempat lahir</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukkan tempat lahir peserta didik"
                  defaultValue={birthplace}
                  onChange={(e) => setBirthplace(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="tgl">
                <Form.Label>
                  <b>Tanggal lahir</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Masukkan tanggal lahir peserta didik"
                  defaultValue={birthday}
                  onChange={(e) => setBirtday(e.target.value)}
                />
                <Form.Text className="text-muted">
                  klik untuk memilih tanggal lahir
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100" controlId="nowaortu">
                <Form.Label>
                  <b>Nomor telepon/Whatsapp Orang tua</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Masukkan nomor telepon orang tua"
                  defaultValue={phonewa}
                  onChange={(e) => setPhonewa(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
                <Form.Label>
                  <b>Email</b>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Masukkan email"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <hr
                style={{
                  background: "none",
                  color: "black",
                  borderColor: "black",
                  height: "3px",
                  width: "100%",
                }}
              />
              <Form.Group className="mb-3 w-100" controlId="formBasic">
                <Form.Label>
                  <b>
                    Dari mana Anda mengetahui Asy-Syukriyyah Islamic School?
                  </b>
                </Form.Label>
                <Form.Select
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                >
                  <option>Pilih sumber</option>
                  <option value={"Website"}>Website</option>
                  <option value={"Facebook"}>Facebook</option>
                  <option value={"Instagram"}>Instagram</option>
                  <option value={"Whatsapp"}>Whatsapp/Line/Telegram</option>
                  <option value={"Brosur"}>Brosur</option>
                  <option value={"Alumni"}>Alumni</option>
                  <option value={"Teman"}>Teman</option>
                  <option value={"Saudara"}>Saudara</option>
                </Form.Select>
              </Form.Group>

              <hr
                style={{
                  background: "none",
                  color: "black",
                  borderColor: "black",
                  height: "3px",
                  width: "100%",
                }}
              />
              {errMessage && (
                <Alert variant="danger">{errMessage.replace(regex, " ")}</Alert>
              )}
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                  onChange={(e) => setCheck(e.target.checked)}
                />
              </Form.Group>
              <Button
                variant="daftar"
                type="submit"
                className="rounded-pill"
                disabled={!check}
              >
                Daftar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <span className="circlex" />
      <span className="circley" />
    </section>
  );
}
export default RegisterInternal;
